/* eslint-disable */
$(document).ready(function () {
    $('.paginator-btn').click(function (event) {
        event.preventDefault();
        let element = $(this);
        element.addClass('paginator-btn_shine');
        location.href = element.attr('href');
        setTimeout(function () {
            element.removeClass('paginator-btn_shine');
        }, 1500);
    })
})
