/* eslint-disable */
$(document).ready(function () {
    const find_town_form_esc = $('.find-town__form-esc ');
    const find_town_inp = $('.find-town__inp_nojqm');
    let path_check_box = "data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214px%22%20height%3D%2214px%22%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20style%3D%22fill%3A%23FFFFFF%3B%22%20points%3D%2214%2C4%2011%2C1%205.003%2C6.997%203%2C5%200%2C8%204.966%2C13%204.983%2C12.982%205%2C13%20%22%2F%3E%3C%2Fsvg%3E";
    let path = "";
    const main_page_town_esc = $('.find-town__esc');
    const main_page_town_inp = $('.find-town__inp');
    const main_page_find_town = $('.list__find-town');
    const main_page_filter_check = $('.main-page__filter-check');
    const main_page_town_btn = $('.main-page__town-btn');
    main_page_town_btn.click(function () {
        $(this).removeClass('main-page__town-btn-shadow');
        setTimeout(function () {
            $('.main-page__town-btn').addClass('main-page__town-btn-shadow');
        }, 200)
    })
    function delete_last_towns () {
        main_page_find_town.empty();
    }
    function refresh_towns_list () {
        delete_last_towns();
        setTimeout(function () {
            main_page_find_town.removeClass("list__find-town_hidden");
        }, 300);
        $.ajax({
            url: "/ac/town/",
            data: {
                q: $('.find-town__inp').val()
            }
        }).then(function (response) {
            var parsed = JSON.parse(response);
            if (parsed.length) {
            }
            $.each(parsed, function (i, val) {
                if (main_page_town_inp.data('link') === 'main-page') {
                    path = val.translit;
                } else {
                    if (location.hostname === PROTABLETKY_DOMAIN) {
                        path = "/apteki-" + val.translit;
                    } else {
                        path = '/' + val.translit;
                    }
                }
                main_page_find_town.append('<li class="list__item list__link_thin"><a class="list__link list__link_shine-on list__link_background list__link_thin list__link_thin-black" href=' + path + ' >' + val.name + '</a></li>');
            })
            if (response === "[]") {
                delete_last_towns();
                main_page_find_town.append('<li class="list__item list__link_thin">' + ' Совпадений не найдено' + '</li>');
            };
        });
    }

    main_page_town_esc.click(function () {
        main_page_town_inp.val("").focus();
        delete_last_towns();
    });

    main_page_town_inp.focus(function (event) {
        if (main_page_town_inp.val().length > 1) {
            refresh_towns_list();
        }
    });

    main_page_town_inp.on('input', function () {
        if (main_page_town_inp.val().length > 1) {
            refresh_towns_list();
        }
    });
    main_page_filter_check.click(function () {
        if ($(this).data("check") === "check") {
            $(this).find('.main-page__filter-check-box').attr("style", "background-image : none");
            $(this).data("check", "uncheck");
            return false;
        } else {
            $(this).find('.main-page__filter-check-box').attr("style", 'background-image : url("' + path_check_box + '")');
            $(this).data("check", "check");
            return false;
        }
    })
    find_town_form_esc.click(function () {
        find_town_inp.val('');
    })
    $('.find-town__goto').click(function (e) {
        var $first_link = $('#town_autocomplete > li:first-child > a');
        var default_href = $(this).attr('href');
        if ($first_link.length) {
            window.location = $first_link.attr('href');
        } else if (default_href) {
            window.location = default_href;
        }
        e.preventDefault();
    });
})
